import logo from "./logo.svg";
import "./App.css";
import AccountCreate from "./scenes/Account/AccountCreate";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_MEET } from "./redux/meetReducer";
import { ThemeProvider, createTheme } from "@mui/material/styles";

function App() {
  const { user } = useSelector((state) => state.user);
  const { network } = useSelector((state) => state.network);
  const { meet } = useSelector((state) => state);
  const allstates = useSelector((state) => state);
  const dispatch = useDispatch();
  useEffect(() => {
    console.log("Env", process.env.REACT_APP_ENV);
    /* console.log("All States", allstates);
    console.log("Meet:", meet);
    dispatch(UPDATE_MEET({ ...meet, channelName: null }));
    console.log("Network:", network);*/
  }, []);

  const theme = createTheme({
    palette: {
      primary: {
        light: "#757ce8",
        main: "#3f50b5",
        dark: "#002884",
        contrastText: "#fff",
      },
      secondary: {
        light: "#ff7961",
        main: "#f44336",
        dark: "#ba000d",
        contrastText: "#000",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <AccountCreate />
      </div>
    </ThemeProvider>
  );
}

export default App;

//source
// flag random hedgehog carry minute dune scatter torch fall coast matter online

//destination
// bean topic flock average boil discover bounce cement chronic speed lock upon

//https://deep-index.moralis.io/api-docs/#/account/getNFTs