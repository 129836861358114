const goerli = {
  chainId: "5",
  name: "Goerli",
  blockExplorerUrl: "https://goerli.etherscan.io",
  rpcUrl: "https://goerli.infura.io/v3/59b59e23bb7c44d799b5db4a1b83e4ee",
};

const mainnet = {
  chainId: "1",
  name: "Ethereum",
  blockExplorerUrl: "https://etherscan.io",
  rpcUrl: "https://mainnet.infura.io/v3/59b59e23bb7c44d799b5db4a1b83e4ee",
};

const mumbai = {
  chainId: "80001",
  name: "Mumbai",
  blockExplorerUrl: "https://mumbai.polygonscan.com/",
  rpcUrl: "https://polygon-mumbai.infura.io/v3/e6c0d81096bf41d29c78e8562e02e67d",
  symbol:"https://icons.llamao.fi/icons/chains/rsz_polygon.jpg",
};

const CHAINS_CONFIG = {
  [goerli.chainId]: goerli,
  [mainnet.chainId]: mainnet,
  [mumbai.chainId]: mumbai,
};

export { goerli, mainnet, mumbai, CHAINS_CONFIG };
