import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  transactions: {},
};

export const transactionSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    UPDATE_TRANSACTIONS: (state, action) => {
      state.transaction = action.payload;
    },
  },
});

export const { UPDATE_TRANSACTIONS } = transactionSlice.actions;

export default transactionSlice.reducer;
