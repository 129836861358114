import React, { useCallback, useEffect, useState } from "react";
import { readVal, sendToken, writeVal } from "../../utils/TransactionUtils";
import { goerli, mumbai } from "../../models/Chain";
import { Account } from "../../models/Account";
import AccountTransactions from "./AccountTransactions";
import { ethers } from "ethers";
import { shortenAddress, toFixedIfNecessary } from "../../utils/AccountUtils";
import "./Account.css";
import { TransactionService } from "../../services/TransactionService";
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_NETWORK } from "../../redux/networkReducer";
import {
  Box,
  Button,
  Divider,
  Input,
  TextField,
  Typography,
  colors,
} from "@mui/material";
import ContentCopyTwoToneIcon from "@mui/icons-material/ContentCopyTwoTone";
import SendIcon from "@mui/icons-material/Send";
import AddIcon from "@mui/icons-material/Add";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const AccountDetail = ({ account }) => {
  const [destinationAddress, setDestinationAddress] = useState("");
  const [amount, setAmount] = useState(0);
  const [balance, setBalance] = useState(account.balance);
  const [transactions, setTransactions] = useState([]);

  const [sendPanel, setsendPanel] = useState(false);

  const { network } = useSelector((state) => state.network);
  const dispatch = useDispatch();

  const getTransactions = () => {
    dispatch(
      UPDATE_NETWORK({
        status: "pending",
        message: "",
      })
    );
    TransactionService.getTransactions(account.address)
      .then((response) => {
        setTransactions(response.data.result);
      })
      .catch((error) => {
        console.log({ error });
        dispatch(
          UPDATE_NETWORK({
            status: "error",
            message: JSON.stringify(error),
          })
        );
      })
      .finally(() => {
        dispatch(
          UPDATE_NETWORK({
            status: "complete",
            message: "",
          })
        );
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      const provider = new ethers.providers.JsonRpcProvider(mumbai.rpcUrl); //Network provider RPC URL
      let accountBalance = await provider.getBalance(account.address);
      setBalance(
        String(toFixedIfNecessary(ethers.utils.formatEther(accountBalance)))
      );
    };
    fetchData();
    getTransactions();
  }, [account.address]);

  function handleDestinationAddressChange(event) {
    setDestinationAddress(event.target.value);
  }

  function handleAmountChange(event) {
    setAmount(Number.parseFloat(event.target.value));
  }

  async function transfer() {
    dispatch(
      UPDATE_NETWORK({
        status: "pending",
        message: "",
      })
    );

    try {
      const { receipt } = await sendToken(
        amount,
        account.address,
        destinationAddress,
        account.privateKey
      );

      if (receipt.status === 1) {
        dispatch(
          UPDATE_NETWORK({
            status: "complete",
            message: (
              <p>
                Transfer complete!{" "}
                <a
                  href={`${mumbai.blockExplorerUrl}/tx/${receipt.transactionHash}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  View transaction
                </a>
              </p>
            ),
          })
        );
        getTransactions();
        return receipt;
      } else {
        console.log(`Failed to send ${receipt}`);
        dispatch(
          UPDATE_NETWORK({
            status: "error",
            message: JSON.stringify(receipt),
          })
        );
        return { receipt };
      }
    } catch (error) {
      console.error({ error });
      dispatch(
        UPDATE_NETWORK({
          status: "error",
          message: error.reason || JSON.stringify(error),
        })
      );
    }
  }
  async function setData() {
    dispatch(
      UPDATE_NETWORK({
        status: "pending",
        message: "",
      })
    );

    try {
      const { receipt } = await writeVal(account.address, account.privateKey);

      if (receipt.status === 1) {
        dispatch(
          UPDATE_NETWORK({
            status: "complete",
            message: (
              <p>
                Transfer complete!{" "}
                <a
                  href={`${mumbai.blockExplorerUrl}/tx/${receipt.transactionHash}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  View transaction
                </a>
              </p>
            ),
          })
        );
        getTransactions();
        return receipt;
      } else {
        console.log(`Failed to send ${receipt}`);
        dispatch(
          UPDATE_NETWORK({
            status: "error",
            message: JSON.stringify(receipt),
          })
        );
        return { receipt };
      }
    } catch (error) {
      console.error({ error });
      dispatch(
        UPDATE_NETWORK({
          status: "error",
          message: error.reason || JSON.stringify(error),
        })
      );
    }
  }
  async function readData() {
    dispatch(
      UPDATE_NETWORK({
        status: "pending",
        message: "",
      })
    );

    try {
      const { readableVal } = await readVal(account.privateKey);

      dispatch(
        UPDATE_NETWORK({
          status: "complete",
          message: <p>Data Fetch complete! {readableVal}</p>,
        })
      );
      getTransactions();
      return readableVal;
    } catch (error) {
      console.error({ error });
      dispatch(
        UPDATE_NETWORK({
          status: "error",
          message: error.reason || JSON.stringify(error),
        })
      );
    }
  }

  return (
    <Box className="AccountDetail container">
      <Box
        mt={3}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography
          variant="p"
          sx={{ fontSize: 18, fontWeight: 500, color: "#000" }}
          mb={1}
        >
          Account Name
        </Typography>
        <Typography
          variant="p"
          sx={{ fontSize: 16, fontWeight: "bold", color: "#6a727e" }}
        >
          <a
            href={`https://mumbai.polygonscan.com/address/${account.address}`}
            target="_blank"
            rel="noreferrer"
            style={{ color: "#6a727e" }}
          >
            {shortenAddress(account.address)}
          </a>
          <ContentCopyTwoToneIcon
            sx={{ cursor: "pointer", fontSize: 16 }}
            color="primary"
            onClick={() => {
              navigator.clipboard.writeText(account.address);
            }}
          />
        </Typography>
      </Box>
      <Divider />
      <Box>
        <Box mt={3}>
          <img src={mumbai.symbol} width="40" style={{ borderRadius: 20 }} />
        </Box>
        <Typography variant="h4">{balance} MATIC</Typography>
      </Box>

      {/* Three Action buttons */}
      <Box mt={3} mb={1} display="flex" justifyContent="center">
        <Button
          sx={{ marginRight: 2 }}
          variant="contained"
          color="secondary"
          size="large"
          onClick={() => {}}
        >
          <AddIcon />
        </Button>
        <Button
          sx={{ marginRight: 2 }}
          variant="contained"
          color="secondary"
          size="large"
          onClick={() => {
            setsendPanel(true);
          }}
          disabled={sendPanel}
        >
          <SendIcon />
        </Button>
        <Button variant="contained" color="secondary" size="large">
          <SwapHorizIcon />
        </Button>
      </Box>
      <Divider />

      {/* Send currency */}
      {sendPanel ? (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={{ width: "100%" }}
        >
          <Divider />
          <Box
            mt={3}
            mb={1}
            display="flex"
            justifyContent="start"
            alignItems="center"
            sx={{ width: "100%" }}
          >
            <Button
              variant="ourlined"
              color="primary"
              sx={{ marginRight: 1 }}
              onClick={() => {
                setsendPanel(false);
              }}
            >
              <ArrowBackIcon />
            </Button>
            <Typography variant="p" sx={{ fontWeight: 300, fontSize: 24 }}>
              Send to
            </Typography>
          </Box>
          <TextField
            sx={{ marginBottom: 2, width: "100%" }}
            variant="outlined"
            type="text"
            value={destinationAddress}
            onChange={handleDestinationAddressChange}
            label="Receiver address"
          />

          <TextField
            sx={{ marginBottom: 2, width: "100%" }}
            variant="outlined"
            type="number"
            value={amount}
            onChange={handleAmountChange}
            label="Amount"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={transfer}
            disabled={!amount || network.status === "pending"}
            size="large"
          >
            Send {amount} MATIC <SendIcon sx={{ marginLeft: 1 }} />
          </Button>
        </Box>
      ) : (
        ""
      )}

      {network.status && (
        <>
          {network.status === "pending" && (
            <Typography variant="body1">Transfer is pending...</Typography>
          )}
          {network.status === "complete" && (
            <Typography variant="body1">{network.message}</Typography>
          )}
          {network.status === "error" && (
            <Typography variant="body1">
              Error occurred while transferring tokens: {network.message}
            </Typography>
          )}
        </>
      )}

      <AccountTransactions
        account={account}
        getTransactions={getTransactions}
        transactions={transactions}
      />
    </Box>
  );
};

export default AccountDetail;

{
  /* <Button
variant="contained"
color="primary"
onClick={setData}
disabled={!amount || network.status === "pending"}
>
Set Data
</Button>
<Button
variant="contained"
color="primary"
onClick={readData}
disabled={network.status === "pending"}
>
Read Data
</Button>
 */
}
