const { ethers, Wallet } = require("ethers");
const { CHAINS_CONFIG, goerli, mumbai } = require("../models/Chain");

export const conNFTAddr = "0x3494e238E3C4dffD88fc2032175897C4D12699eF";
export const FaconAbi = [
  // Mint
  "function mint_nemwNFT(string memory tkuri) external",
  "event MintNFT( uint256 date, address indexed collectionAddr, address indexed from, uint256 indexed tokenid, string tkuri )",
  //Transfer
  "function transfer_safe(address to, uint256 tokenid) external",
  "event TransferNFT_wp( uint256 indexed date, address indexed collectionAddr, uint256 indexed tokenid, address from, address to )",
  //Burn
  "function burnNFT(uint256 tokenid) external",
  "event BurnNFT(uint256 indexed date,address indexed collectionAddr,uint256 indexed tokenid,address from)",
];

const chain = CHAINS_CONFIG[mumbai.chainId];
export const nftProvider = new ethers.providers.JsonRpcProvider(chain.rpcUrl);

export async function mintNFT(privateKey) {
  const chain = CHAINS_CONFIG[mumbai.chainId];

  // Create a nftProvider using the Infura RPC URL for Goerli
  // const nftProvider = new ethers.providers.JsonRpcProvider(chain.rpcUrl);
  const signer = new ethers.Wallet(privateKey, nftProvider);

  const FaconCon = new ethers.Contract(conNFTAddr, FaconAbi, signer);
  const tx = await FaconCon.mint_nemwNFT("https://random.imagecdn.app/500/500");
  const receipt = await tx.wait();

  return { receipt };
}

export async function transferNFT(to, tokenid, privateKey) {
  const chain = CHAINS_CONFIG[mumbai.chainId];

  // Create a nftProvider using the Infura RPC URL for Goerli
  const nftProvider = new ethers.providers.JsonRpcProvider(chain.rpcUrl);
  const signer = new ethers.Wallet(privateKey, nftProvider);

  const FaconCon = new ethers.Contract(conNFTAddr, FaconAbi, signer);
  const tx = await FaconCon.transfer_safe(to, parseInt(tokenid));
  const receipt = await tx.wait();

  return { receipt };
}

export async function burnNFT(tokenid, privateKey) {
  const chain = CHAINS_CONFIG[mumbai.chainId];

  // Create a nftProvider using the Infura RPC URL for Goerli
  const nftProvider = new ethers.providers.JsonRpcProvider(chain.rpcUrl);
  const signer = new ethers.Wallet(privateKey, nftProvider);

  const FaconCon = new ethers.Contract(conNFTAddr, FaconAbi, signer);
  const tx = await FaconCon.burnNFT(parseInt(tokenid));
  const receipt = await tx.wait();

  return { receipt };
}

// module.exports = {
//   mintNFT,
//   transferNFT,
//   burnNFT,
//   FaconAbi,
//   conNFTAddr,
//   nftProvider
// };
