const { ethers, Wallet } = require("ethers");
const { CHAINS_CONFIG, goerli, mumbai } = require("../models/Chain");

export async function sendToken(amount, from, to, privateKey) {
  const chain = CHAINS_CONFIG[mumbai.chainId];

  // Create a provider using the Infura RPC URL for Goerli
  const provider = new ethers.providers.JsonRpcProvider(chain.rpcUrl);

  // Create a wallet instance from the sender's private key
  const wallet = new ethers.Wallet(privateKey, provider);

  // Construct the transaction object
  const tx = {
    to,
    value: ethers.utils.parseEther(amount.toString()),
  };

  // Sign the transaction with the sender's wallet
  const transaction = await wallet.sendTransaction(tx);

  // Wait for the transaction to be mined
  const receipt = await transaction.wait();

  return { transaction, receipt };
}

const SStorageAbi = [
  // Write
  "function set(uint x) public",

  // Read
  "function get() public view returns (uint)",

  // An event triggered whenever anyone transfers to someone else
  " event SetData(uint256 indexed date, uint256 data, address from);",
];

export async function writeVal(from, privateKey) {
  const chain = CHAINS_CONFIG[mumbai.chainId];

  // Create a provider using the Infura RPC URL for Goerli
  const provider = new ethers.providers.JsonRpcProvider(chain.rpcUrl);
  const signer = new ethers.Wallet(privateKey, provider);
  const conAddr = "0x49349DF9F438C2e7f1B707ED2600EacBac63E286";

  const SStorageCon = new ethers.Contract(conAddr, SStorageAbi, signer);
  const tx = await SStorageCon.set(25);
  const receipt = await tx.wait();
  const updated_Val = await SStorageCon.get();
  console.log(ethers.utils.formatEther(updated_Val).toString());

  /* // Receive an event when ANY transfer occurs
  SStorageCon.on("set", (x, event) => {
    console.log(x);
    // The event object contains the verbatim log data, the
    // EventFragment and functions to fetch the block,
    // transaction and receipt and event functions
    console.log(event);
  });*/
  return { receipt, updated_Val };
}

export async function readVal(privateKey) {
  const chain = CHAINS_CONFIG[mumbai.chainId];

  // Create a provider using the Infura RPC URL for Goerli
  const provider = new ethers.providers.JsonRpcProvider(chain.rpcUrl);

  const conAddr = "0x49349DF9F438C2e7f1B707ED2600EacBac63E286";

  const SStorageCon = new ethers.Contract(conAddr, SStorageAbi, provider);

  const val = await SStorageCon.get();
  console.log(val)
  console.log(val.toNumber());
  const readableVal = val.toNumber();
  return { readableVal };
}

// module.exports = {
//   sendToken,
//   writeVal,
//   readVal,
// };
