import { ethers } from "ethers";
import React, { useCallback, useEffect, useState } from "react";
import { Account } from "../../models/Account";
import { goerli, mumbai } from "../../models/Chain";
import { Transaction } from "../../models/Transaction";
import { TransactionService } from "../../services/TransactionService";
import { shortenAddress } from "../../utils/AccountUtils";
import { useSelector } from "react-redux";
import {
  Box,
  Typography,
  Button,
  Tabs,
  Tab,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import CallMadeIcon from "@mui/icons-material/CallMade";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import RefreshIcon from "@mui/icons-material/Refresh";
import AccountNFTFlow from "./AccountNFTFlow";
// import AccountTokenFLow from "./AccountTokenFlow";

const AccountTransactions = ({ account, getTransactions, transactions }) => {
  const { network } = useSelector((state) => state.network);
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box className="AccountTransactions" p={2}>
      <Box className="TransactionsMetaData" mt={3} mb={2}>
        {network.status === "complete" && transactions.length === 0 && (
          <Typography>No transactions found for this address</Typography>
        )}

        {/* Show the network response status and message */}
        {network.status && (
          <>
            {network.status === "pending" && (
              <Typography className="text-info">
                Loading transactions...
              </Typography>
            )}
            {network.status === "error" && (
              <Typography className="text-danger">
                Error occurred while transferring tokens: {network.message}
              </Typography>
            )}
          </>
        )}
      </Box>

      <Box mt={3}>
        <Tabs value={tabValue} onChange={handleTabChange} centered>
          <Tab label="Activity" />
          <Tab label="NFT" />
          <Tab label="Tokens" />
        </Tabs>

        {tabValue === 0 && (
          <Box sx={{ height: 300 }}>
            <Box
              mb={1}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{ width: "100%" }}
            >
              <Typography variant="p" sx={{ fontWeight: 400, fontSize: 20 }}>
                Transactions
              </Typography>
              <Button color="primary" onClick={getTransactions} sx={{ marginRight: 1 }}>
                <RefreshIcon />
              </Button>
            </Box>
            <Table className="table table-striped overflow-auto">
              <TableHead>
                <TableRow>
                  {/* <TableCell>Hash</TableCell> */}
                  <TableCell>From</TableCell>
                  <TableCell>To</TableCell>
                  <TableCell>Value</TableCell>
                  {/* <TableCell>Timestamp</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {transactions.map((transaction) => (
                  <TableRow key={transaction.hash}>
                    {/* <TableCell>
                 <a
                   href={`${mumbai.blockExplorerUrl}/tx/${transaction.hash}`}
                   target="_blank"
                   rel="noopener noreferrer"
                 >
                   {shortenAddress(transaction.hash)}
                 </a>
               </TableCell> */}
                    <TableCell>
                      <a
                        href={`${mumbai.blockExplorerUrl}/address/${transaction.from_address}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {shortenAddress(transaction.from_address)}
                      </a>
                      {transaction.from_address.toLowerCase() ===
                        account.address.toLowerCase() ? (
                        <span className="badge rounded-pill bg-warning">
                          <CallMadeIcon />{" "}
                        </span>
                      ) : (
                        <span className="badge rounded-pill bg-success">
                          <CallReceivedIcon />
                        </span>
                      )}
                    </TableCell>
                    <TableCell>
                      <a
                        href={`${mumbai.blockExplorerUrl}/address/${transaction.to_address}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {shortenAddress(transaction.to_address)}
                      </a>
                    </TableCell>
                    <TableCell>
                      {ethers.utils.formatEther(transaction.value)} MATIC
                      <br />
                      <Typography
                        variant="p"
                        sx={{ fontSize: 12, color: "#00b73e" }}
                      >
                        {new Date(transaction.block_timestamp).toDateString()}
                      </Typography>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        )}

        {/* Content for the "NFT" tab */}

        {tabValue === 1 && (
        <AccountNFTFlow account={account} getTransactions={getTransactions} title="NFTs" />
        )}

        {tabValue === 2 && (
        <AccountNFTFlow account={account} getTransactions={getTransactions} title="Tokens" />
          // <AccountTokenFLow account={account} getTransactions={getTransactions} title="Tokens" />
        )}
      </Box>
    </Box>
  );
};

export default AccountTransactions;
