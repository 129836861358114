import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  network: {
    status: null,
    message: '',
  },
};

export const networkSlice = createSlice({
  name: "network",
  initialState,
  reducers: {
    UPDATE_NETWORK: (state, action) => {
      state.network = action.payload;
    },
  },
});

export const { UPDATE_NETWORK } = networkSlice.actions;

export default networkSlice.reducer;
