import React, { useCallback, useEffect, useState } from "react";
import { generateAccount } from "../../utils/AccountUtils";

import AccountDetail from "./AccountDetail";
import { Box, Button, Input, Typography, colors } from "@mui/material";
import { useTheme } from "@emotion/react";

const recoveryPhraseKeyName = "recoveryPhrase";

function AccountCreate() {
  const [seedphrase, setSeedphrase] = useState("");
  const [account, setAccount] = useState(null);
  const [showRecoverInput, setShowRecoverInput] = useState(false);

  const theme = useTheme();

  const recoverAccount = useCallback(async (recoveryPhrase) => {
    const result = await generateAccount(recoveryPhrase);
    setAccount(result.account);

    if (localStorage.getItem(recoveryPhraseKeyName) !== recoveryPhrase) {
      localStorage.setItem(recoveryPhraseKeyName, recoveryPhrase);
    }
    setShowRecoverInput(false)
  }, []);

  useEffect(() => {
    const localStorageRecoveryPhrase = localStorage.getItem(
      recoveryPhraseKeyName
    );
    if (localStorageRecoveryPhrase) {
      setSeedphrase(localStorageRecoveryPhrase);
      recoverAccount(localStorageRecoveryPhrase);
    }
  }, [recoverAccount]);

  function handleChange(event) {
    setSeedphrase(event.target.value);
  }

  const handleKeyDown = async (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      recoverAccount(seedphrase);
    }
  };

  async function createAccount() {
    const result = await generateAccount();
    setAccount(result.account);
    console.log(result);
    localStorage.setItem(recoveryPhraseKeyName, result.seedPhrase);
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
     
      bgcolor="white"
     
      height="fit-content"
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        border={1}
        borderRadius={5}
        borderColor={theme.palette.secondary.light}
       pt={2}
        bgcolor="white"
        boxShadow={3}
        height="fit-content"
      >
        <Typography variant="h4">Ameoba Wallet</Typography>
        <Box p={1} mt={2}>
          <Button sx={{marginRight:2}} variant="contained" color="primary" onClick={createAccount}>
            Create Account
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() =>
              showRecoverInput
                ? recoverAccount(seedphrase)
                : setShowRecoverInput(true)
            }
            disabled={showRecoverInput && !seedphrase}
          >
            Recover account
          </Button>
          {showRecoverInput && (
            <Box mt={2}>
              <Input
                type="text"
                placeholder="Seedphrase or private key for recovery"
                value={seedphrase}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
              />
            </Box>
          )}
        </Box>
        {account && (
          <>
            <hr />
            <AccountDetail account={account} />
          </>
        )}
      </Box>
    </Box>
  );
}

export default AccountCreate;
