import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Input,
  Typography,
  Divider,
  TextField,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CreateIcon from "@mui/icons-material/Create";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import RefreshIcon from "@mui/icons-material/Refresh";
import CallMadeIcon from "@mui/icons-material/CallMade";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import { useDispatch, useSelector } from "react-redux";
import {
  FaconAbi,
  burnNFT,
  conNFTAddr,
  mintNFT,
  nftProvider,
  transferNFT,
} from "../../utils/NFTUtils";
import { UPDATE_NETWORK } from "../../redux/networkReducer";
import { mumbai } from "../../models/Chain";
import { ethers } from "ethers";
import { shortenAddress } from "../../utils/AccountUtils";
import { TransactionService } from "../../services/TransactionService";

function AccountNFTFlow({ account, getTransactions,title }) {
  const [sendPanel, setsendPanel] = useState(false);
  const [burnPanel, setburnPanel] = useState(false);
  const [destinationAddress, setDestinationAddress] = useState("");
  const [tokenId, settokenId] = useState(null);
  const [tokenScanLink, settokenScanLink] = useState(null);
  const [NFTs, setNFTs] = useState([]);
  const { network } = useSelector((state) => state.network);
  const dispatch = useDispatch();

  useEffect(() => {
    getNFTs();
  }, []);

  const FaconConE = new ethers.Contract(conNFTAddr, FaconAbi, nftProvider);
  FaconConE.on(
    "MintNFT",
    (date, collectionAddr, from, tokenid, tkuri, event) => {
      /*  console.log("Date:", new Date(date.toNumber()).toDateString());
      console.log("Collection Addres:", collectionAddr);
      console.log("Creator:", from);
      console.log("URI:", tkuri);
      console.log("Token Id:", tokenid.toNumber()); */
      /* `${mumbai.blockExplorerUrl}/tx/${transaction.from_address}` */
      settokenScanLink(
        `${mumbai.blockExplorerUrl}token/${collectionAddr}?a=${tokenid}`
      );

      //console.log(event);
    }
  );

  FaconConE.on(
    "TransferNFT_wp",
    (date, collectionAddr, tokenid, from, to, event) => {
      //console.log(date, collectionAddr, tokenid, from, to);
      settokenScanLink(
        `${mumbai.blockExplorerUrl}token/${collectionAddr}?a=${tokenid}`
      );
      //  console.log(event);
    }
  );

  FaconConE.on("BurnNFT", (date, collectionAddr, tokenid, from, event) => {
    //console.log(date, collectionAddr, tokenid, from);
    settokenScanLink(
      `${mumbai.blockExplorerUrl}token/${collectionAddr}?a=${tokenid}`
    );
    //console.log(event);
  });

  const mint = async () => {
    settokenScanLink(null);
    dispatch(
      UPDATE_NETWORK({
        status: "pending",
        message: "",
      })
    );

    try {
      const { receipt } = await mintNFT(account.privateKey);
      /* console.log(receipt); */

      if (receipt.status == 1) {
        dispatch(
          UPDATE_NETWORK({
            status: "complete",
            message: (
              <p>
                Mint complete!{" "}
                <a href={tokenScanLink} target="_blank" rel="noreferrer">
                  View nft
                </a>
              </p>
            ),
          })
        );
        getTransactions();
        getNFTs();
      } else {
        console.log(`Failed to send ${receipt}`);
        dispatch(
          UPDATE_NETWORK({
            status: "error",
            message: JSON.stringify(receipt),
          })
        );
      }
    } catch (error) {
      console.error({ error });
      dispatch(
        UPDATE_NETWORK({
          status: "error",
          message: error.reason || JSON.stringify(error),
        })
      );
    }
    console.log("Network:", network);
  };
  const transfer = async () => {
    settokenScanLink(null);
    dispatch(
      UPDATE_NETWORK({
        status: "pending",
        message: "",
      })
    );

    try {
      const { receipt } = await transferNFT(
        destinationAddress,
        tokenId,
        account.privateKey
      );
      /* console.log(receipt); */

      if (receipt.status == 1) {
        dispatch(
          UPDATE_NETWORK({
            status: "complete",
            message: (
              <p>
                Transfer complete!{" "}
                <a
                  href={`${mumbai.blockExplorerUrl}/token/0x3494e238e3c4dffd88fc2032175897c4d12699ef/${receipt.transactionHash}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  View nft
                </a>
              </p>
            ),
          })
        );
        getTransactions();
        getNFTs();
      } else {
        console.log(`Failed to send ${receipt}`);
        dispatch(
          UPDATE_NETWORK({
            status: "error",
            message: JSON.stringify(receipt),
          })
        );
      }
    } catch (error) {
      console.error({ error });
      dispatch(
        UPDATE_NETWORK({
          status: "error",
          message: error.reason || JSON.stringify(error),
        })
      );
    }
  };
  const burn = async () => {
    settokenScanLink(null);
    dispatch(
      UPDATE_NETWORK({
        status: "pending",
        message: "",
      })
    );

    try {
      const { receipt } = await burnNFT(tokenId, account.privateKey);
      /* console.log(receipt); */

      if (receipt.status == 1) {
        dispatch(
          UPDATE_NETWORK({
            status: "complete",
            message: (
              <p>
                Burn complete!{" "}
                <a
                  href={`${mumbai.blockExplorerUrl}/token/0x3494e238e3c4dffd88fc2032175897c4d12699ef/${receipt.transactionHash}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  View nft
                </a>
              </p>
            ),
          })
        );
        getTransactions();
        getNFTs();
      } else {
        console.log(`Failed to send ${receipt}`);
        dispatch(
          UPDATE_NETWORK({
            status: "error",
            message: JSON.stringify(receipt),
          })
        );
        return { receipt };
      }
    } catch (error) {
      console.error({ error });
      dispatch(
        UPDATE_NETWORK({
          status: "error",
          message: error.reason || JSON.stringify(error),
        })
      );
    }
  };

  const getNFTs = () => {
    dispatch(
      UPDATE_NETWORK({
        status: "pending",
        message: "",
      })
    );
    TransactionService.getNFTTransactions(account.address)
      .then((response) => {
        console.log(response);
        setNFTs(response.data.result);
      })
      .catch((error) => {
        console.log({ error });
        dispatch(
          UPDATE_NETWORK({
            status: "error",
            message: JSON.stringify(error),
          })
        );
      })
      .finally(() => {
        dispatch(
          UPDATE_NETWORK({
            status: "complete",
            message: "",
          })
        );
      });
  };

  return (
    <Box p={2}>
      <Box
        mb={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: "100%" }}
      >
        <Typography variant="p" sx={{ fontWeight: 400, fontSize: 20 }}>
          {title}
        </Typography>
        <Button color="primary" onClick={getNFTs} sx={{ marginRight: 1 }}>
          <RefreshIcon />
        </Button>
      </Box>
      <Box display="flex" justifyContent="space-between" width="100%">
        <Button
          sx={{ marginRight: 1 }}
          variant="contained"
          color="primary"
          onClick={mint}
          disabled={network.status === "pending"}
        >
          Mint <CreateIcon sx={{ paddingLeft: 1 }} />
        </Button>
        <Button
          sx={{ marginRight: 1 }}
          variant="contained"
          color="primary"
          onClick={() => {
            setsendPanel(true);
          }}
          disabled={network.status === "pending"}
        >
          Send <SendIcon sx={{ paddingLeft: 1 }} />
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setburnPanel(true);
          }}
          disabled={network.status === "pending"}
        >
          Burn <DeleteIcon sx={{ paddingLeft: 1 }} />
        </Button>
      </Box>
      {tokenScanLink ? (
        <Typography variant="p">
          {network.message}
          <br />
          <a href={tokenScanLink} target="_blank" rel="noreferrer">
            View Details
          </a>
        </Typography>
      ) : (
        ""
      )}

      {/* Send NFT */}
      {sendPanel ? (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={{ width: "100%" }}
        >
          <Divider />
          <Box
            mt={3}
            mb={1}
            display="flex"
            justifyContent="start"
            alignItems="center"
            sx={{ width: "100%" }}
          >
            <Button
              variant="ourlined"
              color="primary"
              sx={{ marginRight: 1 }}
              onClick={() => {
                setsendPanel(false);
              }}
            >
              <ArrowBackIcon />
            </Button>
            <Typography variant="p" sx={{ fontWeight: 300, fontSize: 24 }}>
              Send to
            </Typography>
          </Box>
          <TextField
            sx={{ marginBottom: 2, width: "100%" }}
            variant="outlined"
            type="text"
            value={destinationAddress}
            onChange={(e) => {
              setDestinationAddress(e.target.value);
            }}
            label="Receiver address"
          />
          <TextField
            sx={{ marginBottom: 2, width: "100%" }}
            variant="outlined"
            type="text"
            value={tokenId}
            onChange={(e) => {
              settokenId(e.target.value);
            }}
            label="Token Id"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={transfer}
            disabled={!tokenId || network.status === "pending"}
            size="large"
          >
            Send NFT <SendIcon sx={{ marginLeft: 1 }} />
          </Button>
        </Box>
      ) : (
        ""
      )}

      {/* Burn NFT */}
      {burnPanel ? (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={{ width: "100%" }}
        >
          <Divider />
          <Box
            mt={3}
            mb={1}
            display="flex"
            justifyContent="start"
            alignItems="center"
            sx={{ width: "100%" }}
          >
            <Button
              variant="ourlined"
              color="primary"
              sx={{ marginRight: 1 }}
              onClick={() => {
                setburnPanel(false);
              }}
            >
              <ArrowBackIcon />
            </Button>
            <Typography variant="p" sx={{ fontWeight: 300, fontSize: 24 }}>
              Burn NFT
            </Typography>
          </Box>

          <TextField
            sx={{ marginBottom: 2, width: "100%" }}
            variant="outlined"
            type="text"
            value={tokenId}
            onChange={(e) => {
              settokenId(e.target.value);
            }}
            label="Token Id"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={burn}
            disabled={!tokenId || network.status === "pending"}
            size="large"
          >
            Burn NFT <DeleteIcon sx={{ paddingLeft: 1 }} />
          </Button>
        </Box>
      ) : (
        ""
      )}

      {/* Content for the "NFT" list tab */}
      <Box>
        <Box sx={{ height: 300 }}>
          <Table className="table table-striped overflow-auto">
            <TableHead>
              <TableRow>
                {/* <TableCell>Hash</TableCell> */}
                <TableCell>Address</TableCell>
                <TableCell>Token Hash</TableCell>
                <TableCell>Id</TableCell>
                {/* <TableCell>Timestamp</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {NFTs.map((nft) => (
                <TableRow key={nft.token_id}>
                  {/* <TableCell>
                <a
                  href={`${mumbai.blockExplorerUrl}/tx/${nft.hash}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {shortenAddress(nft.hash)}
                </a>
              </TableCell> */}
                  <TableCell>
                    <a
                      href={`${mumbai.blockExplorerUrl}token/${nft.token_address}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {shortenAddress(nft.token_address)}
                    </a>
                  </TableCell>
                  <TableCell>{shortenAddress(nft.token_hash)}</TableCell>
                  <TableCell>
                    <a
                      href={`${mumbai.blockExplorerUrl}token/${nft.token_address}?a=${nft.token_id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {nft.token_id}
                    </a>

                    <br />
                    <Typography
                      variant="p"
                      sx={{ fontSize: 12, color: "#00b73e" }}
                    >
                      {new Date(nft.last_token_uri_sync).toDateString()}
                    </Typography>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Box>
    </Box>
  );
}

export default AccountNFTFlow;
