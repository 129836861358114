import axios from 'axios';
import { goerli,mumbai } from '../models/Chain';

export class TransactionService {
  static API_URL = 'https://deep-index.moralis.io/api/v2';
  static API_KEY = 'PeXDxQytDkm5j4H00hEj3cW5nRg74m3BILhQBiiyCRMH5kOko8KNe6Vch5QgKJZL';

  static async getTransactions(address) {
    const options = {
      method: 'GET',
      url: `${TransactionService.API_URL}/${address}`,
      params: { chain: mumbai.name.toLowerCase() },
      headers: { accept: 'application/json', 'X-API-Key': TransactionService.API_KEY },
    };

    const response = await axios.request(options);
    //console.log (response)
    return response;
  }
  static async getNFTTransactions(address) {
    const options = {
      method: 'GET',
      url: `${TransactionService.API_URL}/${address}/nft`,
      params: { chain: mumbai.name.toLowerCase() },
      headers: { accept: 'application/json', 'X-API-Key': TransactionService.API_KEY },
    };

    const response = await axios.request(options);
    //console.log (response)
    return response;
  }

  static async getTokenTransactions(address) {
    const options = {
      method: 'GET',
      url: `${TransactionService.API_URL}/${address}/erc20`,
      params: { chain: mumbai.name.toLowerCase() },
      headers: { accept: 'application/json', 'X-API-Key': TransactionService.API_KEY },
    };

    const response = await axios.request(options);
    console.log (response)
    return response;
  }
}
